import React from 'react';
import Subheader from './../components/SubHeader';
import family from './../images/about.jpg';
import SEO from './../components/SEO';


export default function About() {
    return (
        <>
            <SEO title="About" />
            <Subheader title_main="About us" title_sub="who we are"/>
            <div className="pt-16 xl:py-24">
                <div className="pb-16 bg-yellow-400 xl:pb-0 xl:z-10 xl:relative">
                    <div className="xl:mx-auto xl:max-w-9xl xl:px-8 xl:grid xl:grid-cols-2 xl:gap-8">
                        <div className="relative xl:-my-8">
                            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 xl:hidden" style={{background: '#f3f3f3'}} />
                                <div className="mx-auto max-w-xl px-4 sm:max-w-3xl sm:px-6 xl:p-0 xl:h-full">
                                    <div className="aspect-w-10 aspect-h-8 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-12 xl:aspect-none xl:h-full">
                                    <img
                                        className="object-cover xl:h-full xl:w-full"
                                        src={family}
                                        alt="family"
                                    />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12 xl:m-0  xl:pl-8">
                                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 xl:px-0 xl:py-20 xl:max-w-none">
                                    <blockquote>
                                    <div>
                                        <svg
                                        className="h-12 w-12 text-gray-900 opacity-25"
                                        fill="currentColor"
                                        viewBox="0 0 32 32"
                                        aria-hidden="true"
                                        >
                                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                        </svg>
                                        <p className="mt-6 text-xl font-medium text-gray-900">
                                        My name is Brody Thompson and my wife, Amanda and I are beekeepers with a couple of years experience in the Brazoria area. We are dedicated to the live removal and relocation of honeybees in conflict with human activity. I have mentored with an experienced beekeeper for many years and when he decided to sell off his business, he gifted us some hives to start our own beekeeping journey! We are proud parents of three beautiful children and the proud owners of 18 currently very strong and growing hives! We have had dozens of successful removals and just harvested our first year crop of honey!
                                        </p>
                                    </div>
                                    <footer className="mt-6">
                                        <p className="text-base font-medium text-gray-900">Brody Thompson</p>
                                        <p className="text-base font-medium text-gray-900">Owner</p>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
  }